import React, { useEffect } from 'react';
import favicon from '../../../../assets/favicons/favicon.png';

const Favicon = ({ href = favicon, type = 'image/png' }) => {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = type;
    link.href = href;

    document.head.appendChild(link);

    return () => {
      // Cleanup: remove the dynamically added link element
      document.head.removeChild(link);
    };
  }, [href, type]);

  return null; // Favicon component doesn't render anything
};

export default Favicon;
