import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { deleteAllCookies } from 'smart-react';
import getTokensFromStorage from '../../../Utils/Auth/AuthToken';
import { USER_DATA } from '../../../constants/applicationConstants';

/**
 * Context for managing authentication-related states and actions.
 * @type {React.Context}
 */
export const AuthContext = React.createContext();

/**
 * Hook for accessing authentication context values.
 * @returns {Object} Authentication context values.
 */
export const useAuth = () => React.useContext(AuthContext);

/**
 * Provider component for managing authentication state and actions.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child components to be wrapped by the provider.
 * @returns {JSX.Element} Rendered component.
 */
export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [userAuthObj, setUserAuthObj] = React.useState(null);

  /**
   * Update user information in the context.
   * @param {Object} userdt - User data.
   * @param {string} userdt.name - User's full name.
   * @param {string} userdt.email - User's email address.
   */
  const updateUser = (userdt) => {
    setUser({
      full_name: `${userdt?.name}`,
      email: `${userdt?.email}`,
    });
  };

  /**
   * Logout user by clearing session storage, deleting cookies, and redirecting to login page.
   */
  const logout = () => {
    sessionStorage.clear();
    deleteAllCookies();
    window.location.replace('/login');
  };
  /**
   * Logout user by clearing session storage, deleting cookies, and redirecting to login page.
   */
  const login = () => {
    window.location.replace('/login');
  };

  /**
   * Check for presence of access token in session storage.
   * @returns {boolean} Indicates whether access token is present.
   */
  const checkTokens = () => {
    const { accessToken } = getTokensFromStorage();
    if (accessToken) {
      // Check token for expiration
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    const { accessToken } = getTokensFromStorage();
    if (!user && accessToken) {
      const userDetail = sessionStorage.getItem(USER_DATA);
      updateUser(JSON.parse(userDetail));
    }
  }, []);

  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN} // Replace with your Auth0 domain
      clientId={process.env.AUTH0_CLIENT_ID} // Replace with your Auth0 client ID
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <AuthContext.Provider
        value={{
          logout,
          login,
          user,
          updateUser,
          userAuthObj,
          getTokensFromStorage,
          checkTokens,
        }}
      >
        {children}
      </AuthContext.Provider>
    </Auth0Provider>
  );
};
