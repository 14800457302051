import React, { useState, useEffect } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useDropzone } from 'react-dropzone';
import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { SliderCard } from 'smart-react';
import { buildNotification, GenerateNotification } from 'smart-react';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import { ASSISTANTS_TYPES } from '../../../../constants/applicationConstants';

const AssistantForm = ({ assistant, actions, title }) => {
  const {
    assistantName,
    setAssistantName,
    assistantInstructions,
    setAssistantInstructions,
    droppedFiles,
    handleDeleteFile,
    setDroppedFiles,
    selectedFiles,
    setSelectedFiles,
    templates,
    selectedOption,
    setSelectedOption,
    setAssistantType,
    assistantType,
    otherAssistantData,
    setOtherAssistantData,
    isUpdate,
  } = assistant;
  const { handleCancel, handleSubmit, handleSlide } = actions;

  const [selectedType, setSelectedType] = useState({ text: 'Please Select', value: '' });
  const [endpoint, setEndpoint] = useState('');
  const [serverid, setServerId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isUpdate) {
      // If it's an update, set the selected type based on existing data
      setSelectedType({ text: assistantType, value: assistantType });
      setEndpoint(otherAssistantData?.endpoint);
      setServerId(otherAssistantData?.serverid);
      setUsername(otherAssistantData?.username);
      setPassword(otherAssistantData?.password);
    }
  }, [isUpdate, selectedOption, otherAssistantData]);

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    setAssistantType(event.target.value.value);
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setAssistantInstructions(event.target.value.description);
  };

  const validateOpenAIFields = () => {
    let isValid = true;
    if (assistantName?.trim() === '' || !assistantInstructions?.trim()) {
      GenerateNotification(
        buildNotification({
          title: 'Assistant Name and Instructions are Required!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
      isValid = false;
    }
    return isValid;
  };

  const validateSmartRagFields = () => {
    let isValid = true;
    if (assistantName?.trim() === '') {
      GenerateNotification(
        buildNotification({
          title: 'Assistant Name Is Required!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
      isValid = false;
    } else if (droppedFiles.length === 0 && selectedFiles.length === 0) {
      GenerateNotification(
        buildNotification({
          title: 'At least one file is required for SmartAI type!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
      isValid = false;
    }
    return isValid;
  };

  const handleFormSubmit = () => {
    if (selectedType.value === 'OpenAI') {
      if (validateOpenAIFields()) {
        handleSubmit();
      }
    }

    if (selectedType.value === 'SmartAI') {
      if (validateSmartRagFields()) {
        handleSubmit(otherAssistantData);
      }
    }

    if (selectedType.value === 'BlueYonder') {
      if (
        assistantName.trim() === '' ||
        endpoint.trim() === '' ||
        serverid.trim() === '' ||
        username.trim() === '' ||
        password.trim() === ''
      ) {
        GenerateNotification(
          buildNotification({
            title: 'All fields are required!',
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        // Set the assistant data
        const formData = {
          endpoint,
          serverid,
          username,
          password,
        };
        handleSubmit(formData);
      }
    }
  };

  const getAcceptedFileTypes = () => {
    if (selectedType.value === 'SmartAI') {
      return {
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc', '.docx'],
        'application/vnd.ms-excel': ['.xls', '.xlsx'],
        'text/plain': ['.txt'],
        'text/markdown': ['.md'],
      };
    }
    return {
      'application/pdf': ['.pdf'],
    };
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: getAcceptedFileTypes(),
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        // Show error message for invalid file type
        GenerateNotification(
          buildNotification({
            title: 'Invalid file type! Only PDF files are allowed for this assistant type.',
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        // Add accepted files to the droppedFiles state
        setDroppedFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
      }
    },
  });
  return (
    <SliderCard
      title={title}
      handleSlide={handleSlide}
      className='ai-slider'
    >
      <SliderCard.Body>
        <div className='card-content'>
          <div className='k-p-2'>
            <label>Type:</label>
            <DropDownList
              data={ASSISTANTS_TYPES}
              value={selectedType}
              onChange={handleTypeChange}
              textField='text'
              dataItemKey='value'
              defaultValue={selectedType}
              disabled={isUpdate} // Disable the type field on edit
            />
          </div>
          {selectedType.value === 'OpenAI' && (
            <>
              <div className='k-p-2'>
                <label>Name:</label>
                <Input value={assistantName} onChange={(e) => setAssistantName(e.target.value)} />
              </div>
              <div className='k-p-2'>
                <label>Templates:</label>
                <DropDownList
                  data={[{ name: 'Please Select', description: '' }, ...templates]}
                  value={selectedOption}
                  onChange={handleDropdownChange}
                  defaultValue={selectedOption}
                  textField='name'
                  dataItemKey='description'
                  className='form-control'
                />
              </div>
              <div className='k-p-2'>
                <label style={{ marginRight: '10px' }}>Instructions:</label>
                <textarea
                  value={assistantInstructions}
                  onChange={(e) => setAssistantInstructions(e.target.value)}
                  className='form-control k-display-inline-block k-width-100 k-text-area'
                  rows={15}
                  required
                />
              </div>
              <div className='k-p-2'>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                {selectedFiles.length > 0 && (
                  <>
                    <label>Associated Files:</label>
                    <div className='uploaded-files'>
                      {selectedFiles.map((file, index) => (
                        <div key={index} className='custom-card'>
                          <div className='card-body'>
                            <span className='file-name'>{file.file_name}</span>
                            <button className='delete-button k-button-icon k-font-icon k-i-trash'
                                    onClick={() => handleDeleteFile(file.id)} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <div className='uploaded-files'>
                  {droppedFiles.map((file, index) => (
                    <div key={index} className='custom-card'>
                      <div className='card-body'>
                        <span className='file-name'>{file.name}</span>
                        <button className='delete-button k-button-icon k-font-icon k-i-trash'
                                onClick={() => setDroppedFiles(prevFiles => prevFiles.filter((_, i) => i !== index))} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {selectedType.value === 'SmartAI' && (
            <>
              <div className='k-p-2'>
                <label>Name:</label>
                <Input value={assistantName} onChange={(e) => setAssistantName(e.target.value)} />
              </div>
              <div className='k-p-2'>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                {selectedFiles.length > 0 && (
                  <>
                    <label>Associated Files:</label>
                    <div className='uploaded-files'>
                      {selectedFiles.map((file, index) => (
                        <div key={index} className='custom-card'>
                          <div className='card-body'>
                            <span className='file-name'>{file?.file_name || file?.filename}</span>
                            <button className='delete-button k-button-icon k-font-icon k-i-trash'
                                    onClick={() => handleDeleteFile(file.id)} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <div className='uploaded-files'>
                  {droppedFiles.map((file, index) => (
                    <div key={index} className='custom-card'>
                      <div className='card-body'>
                        <span className='file-name'>{file.name}</span>
                        <button className='delete-button k-button-icon k-font-icon k-i-trash'
                                onClick={() => setDroppedFiles(prevFiles => prevFiles.filter((_, i) => i !== index))} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {selectedType.value === 'BlueYonder' && (
            <>
              <div className='k-p-2'>
                <label>Name:</label>
                <Input value={assistantName} onChange={(e) => setAssistantName(e.target.value)} />
              </div>
              <div className='k-p-2'>
                <label>Endpoint:</label>
                <Input value={endpoint} onChange={(e) => setEndpoint(e.target.value)} />
              </div>
              <div className='k-p-2'>
                <label>Server:</label>
                <Input value={serverid} onChange={(e) => setServerId(e.target.value)} />
              </div>
              <div className='k-p-2'>
                <label>Username:</label>
                <Input value={username} onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div className='k-p-2'>
                <label>Password:</label>
                <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
            </>
          )}
        </div>
      </SliderCard.Body>
      <SliderCard.Footer>
        <DialogActionsBar>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button className='ai-button-primary'
                  onClick={handleFormSubmit}>{isUpdate ? 'Update' : 'Create'} Assistant</Button>
        </DialogActionsBar>
      </SliderCard.Footer>
    </SliderCard>
  );
};

export default AssistantForm;
